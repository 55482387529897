<template>
  <div>
    <Banner
      :image="require('@/assets/image/audition/audition.svg')"
      title="安全测评认证"
      body="区块链企业测评平台和监管平台，联合工信部电标院、信通院、赛迪研究院等权威机构为区块链企业提供功能、性能、安全性、智能合约代码审计等的检测与评估。
      同时根据政府需要，可为政府及执法部门提供区块链企业监管，提升区块链技术及应用的合规性和规范性，建立健全区块链监管体系。"
    />
    <el-row
      :gutter="20"
      type="flex"
      justify="center"
      style="
        padding-left: 8vw;
        padding-right: 8vw;
        margin-top: 20px;
        margin-right: 0;
      "
    >
      <div style="max-width: 1268px;margin-top: 61px;margin-bottom: 67px;
      display:flex;justify-content: center">
        <el-col :span="7" style="margin-right: 25px;"
                v-for="item in tableData" :key="item.index">
        <el-card class="box-card " style="height: 100%" shadow="always"
                 :body-style="{ padding: '24px'}">
          <div  class="clearfix box-title">
          <el-image :src="item.url"/>
          </div>
          <div  class="clearfix box-title">
            <span>{{ item.name }}</span>
          </div>
          <div class="text  box-item">
              <div class="text  box-content">
                {{ item.body }}
              </div>
            </div>
          </el-card>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
import { Banner } from '@/components/common/Index.vue';

export default {
  components: {
    Banner,
  },
  data() {
    return {
      highBoxCard:
        '区块链企业测评平台和监管平台，联合工信部电标院、信通院、赛迪研究院等权威机构为区块链企业提供功能、性能、安全性、智能合约代码审计等的检测与评估。同时根据政府需要，可为政府及执法部门提供区块链企业监管，提升区块链技术及应用的合规性和规范性，建立健全区块链监管体系。',
      tableData: [
        {
          name: '中国电子技术标准化研究院',
          url: require('@/assets/image/audition/ESI.png'),
          body:
            '电子标准院以电子信息技术标准化工作为核心，通过开展标准科研、检测、计量、认证、信息服务等业务，面向政府提供政策研究、行业管理和战略决策的专业支撑，面向社会提供标准化技术服务。电子标准院承担55个IEC、ISO/IEC JTC1的TC/SC国内技术归口和17个全国标准化技术委员会秘书处的工作，与多个国际标准化组织及国外著名机构建立了合作关系，为标准的应用推广、产业推动和国际交流合作发挥了重要的促进作用。',
        },
        {
          name: '中国信通院',
          url: require('@/assets/image/audition/CAICT.png'),
          body:
            '中国信息通信研究院（以下简称“中国信通院”）始建于1957年，是工业和信息化部直属科研事业单位。多年来，中国信通院始 终秉持“国家高端专业智库\
            产业创新发展平 台”的发展定位和“厚德实学 兴业致远”的\
            核心文化价值理念，在行业发展的重大战略、\
            规划、政策、标准和测试认证等方面发挥了\
            有力支撑作用，为我国通信业跨越式发展和\
            信息技术产业创新壮大起到了重要推动作用。',
        },
        {
          name: '赛迪',
          url: require('@/assets/image/audition/CCID.png'),
          body:
            '中国电子信息产业发展研究院（赛迪集\
            团）是直属于国家工业和信息化部的一类科研事业单位。\
            成立二十多年来，一直致力于面向政府、\
            面向企业、面向社会提供研究咨询、评测认\
            证、媒体传播与技术研发等专业服务。形成了\
            政府决策与软科学研究、传媒与网络服务、咨\
            询与外包服务、评测与认证服务、软件开发与\
            信息技术服务五业并举发展的业务格局。',
        },
      ],
    };
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.high-box-card {
  width: calc(100% - 100px);
  min-width: 730px;
  text-align: center;
  margin-top: 20px;
  margin-left: 50px;
}
.box-card {
  padding: 0px;
}
.el-card__body{
}
.box-item{
  min-height: 240px;
  margin-top: 22px;
}
.box-title{
  font-size: 18px;
  font-family: PingFang SC Bold, PingFang SC Bold-Bold;
  font-weight: 700;
  color: #333333;
}
.box-content{
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #888888;
  line-height: 24px;
}
</style>
